var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"c126b7ddd17aab338ab3b71b2b57c3ea5756dea9"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from '@sentry/nextjs';
import * as process from 'process';

const SENTRY_DSN = process.env.NEXT_PUBLIC_SENTRY_DSN;
const NODE_ENV = process.env.NEXT_PUBLIC_NODE_ENV;
const DISABLED = process.env.NEXT_PUBLIC_DISABLE_LOGS === 'true' || false;
const IS_PROD = process.env.NEXT_PUBLIC_NODE_ENV === 'production';

Sentry.init({
  dsn: SENTRY_DSN,
  environment: NODE_ENV,
  ...(DISABLED && { enabled: false }),
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  ...(IS_PROD ? { tracesSampleRate: 0.1 } : { tracesSampleRate: 0 }),
  tracesSampleRate: 0.1,
  // tracePropagationTargets: [
  //   'localhost',
  //   /^https:\/\/teststudyhall\.dev/,
  //   /^https:\/\/studyhallapp\.com/,
  //   /^https:\/\/app.studyhall\.ai/,
  // ],

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  ...(IS_PROD
    ? { replaysSessionSampleRate: 0.1 }
    : { replaysSessionSampleRate: 0 }),

  replaysOnErrorSampleRate: 1.0,

  // Set profilesSampleRate to 1.0 to profile every transaction.
  // Since profilesSampleRate is relative to tracesSampleRate,
  // the final profiling rate can be computed as tracesSampleRate * profilesSampleRate
  // For example, a tracesSampleRate of 0.5 and profilesSampleRate of 0.5 would
  // results in 25% of transactions being profiled (0.5*0.5=0.25)
  // (0.1*0.5 = 0.05 = 5%)
  profilesSampleRate: 0.5,

  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps

  // Replay may only be enabled for the client-side
  integrations: [
    new Sentry.Replay({
      // Additional SDK configuration goes in here, for example:
      maskAllText: true,
      blockAllMedia: true,
    }),
  ],
  debug: false,
});
